import React from "react"

import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import BannerPlain from "../containers/BannerPlainSection"
import ContactForm from "../containers/ContactForm"
import BannerBG from "../images/banner-bg1.svg"

export default () => {
  return (
    <Layout>
      <SEOPage title="Contact Us" description="Cylynx - contact us"/>
      <BannerPlain title="Contact Us" background={BannerBG} />
      <ContactForm />
    </Layout>
  )
}
