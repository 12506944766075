/* eslint-disable global-require */
import React, { useState } from "react"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { StatefulInput } from "baseui/input"
import { StatefulTextarea } from "baseui/textarea"
import { Block } from "baseui/block"
import { Grid, Cell } from "baseui/layout-grid"
import axios from "axios"
import { useForm } from "react-hook-form"
import Map from "pigeon-maps"
import Marker from "pigeon-marker"
import FeatureBlock from "../../components/FeatureBlock"
import Text from "../../components/Text"
import { Description } from "../../components/typography"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"

const position = [1.298031, 103.787799]

const mapTilerProvider = (x, y, z) =>
  `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`

const PigeonMap = () => (
  <Block width={["100%", "100%", "100%", "350px"]} height="370px">
    <Map provider={mapTilerProvider} center={position} defaultZoom={13}>
      <Marker anchor={position} payload={1} />
    </Map>
  </Block>
)

const ContactForm = ({ titleStyle, description }) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const { handleSubmit, register } = useForm()
  const onSubmit = data => {
    axios
      .post(
        "https://usebasin.com/f/adb8b7d14381.json",
        data
      )
      .then(() => {
        setIsSubmit(true)
      })
  }

  return (
    <Block paddingTop="60px">
      <Container>
        <Grid>
          <Cell span={8}>
            <Block paddingTop="40px" paddingBottom="10px">
              <FeatureBlock
                title={<Heading content="Contact Us" {...titleStyle} />}
              />
            </Block>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl label="Name*">
                <StatefulInput
                  required
                  name="name"
                  placeholder=""
                  inputRef={register}
                />
              </FormControl>
              <FormControl label="Email*">
                <StatefulInput
                  required
                  type="email"
                  name="email"
                  placeholder=""
                  inputRef={register}
                />
              </FormControl>
              <FormControl label="Subject*">
                <StatefulInput
                  required
                  name="subject"
                  placeholder=""
                  inputRef={register}
                />
              </FormControl>
              <FormControl label="Message*">
                <StatefulTextarea
                  required
                  name="message"
                  placeholder="Kindly leave a message and we will get back to you as soon as possible"
                  inputRef={register}
                />
              </FormControl>
              {!isSubmit && <Button id="contact-us-success">Submit</Button>}
              {/* Honey Pot */}
              <div style={{ position: "absolute", left: "-5000px" }}>
                <input
                  type="checkbox"
                  name="free_range_forest_green_argyle_door"
                  value="0"
                  tabIndex="-1"
                  autoComplete="no"
                />
              </div>
              {isSubmit && <Description>Thank you for contacting us. We will get back to you as soon as possible!</Description>}
            </form>
          </Cell>
          <Cell span={4}>
            <Block paddingTop="40px" paddingBottom="10px">
              <FeatureBlock
                title={<Heading content="Our Office" {...titleStyle} />}
              />
            </Block>
            <Block paddingBottom="20px">
              <Text
                content="75 Ayer Rajah Crescent #03-06 Singapore 139953"
                {...description}
              />
            </Block>
            <PigeonMap />
          </Cell>
        </Grid>
      </Container>
    </Block>
  )
}

ContactForm.defaultProps = {
  titleStyle: {
    fontSize: ["24px", "26px", "30px", "30px", "30px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    textAlign: ["center", "center", "center", "left", "left"],
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "16px",
    maxWidth: ["100%", "100%", "100%", "100%", "415px"],
  },
  description: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#525f7f",
    lineHeight: "28px",
    mb: 0,
  },
}

export default ContactForm
